import { Box, CircularProgress, Divider, Grid, Skeleton, Typography } from '@mui/material';
import BoxW from '../../../components/wrapper/BoxW';
import Scheduler from '../../../components/scheduler/Scheduler';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect } from 'react';
import useLocation from '../../../components/hook/useLocation';
import ActivityService from '../../../services/ActivityService';
import { TooltipW } from '../../../components/context/ThemeChangeContext';
import CustomDialog from '../../../components/custom-dialog/CustomDialog';
import { SimpleContentDisplay } from '../../../components/card-activity/CardActivity';
import SpeakerInfoCard from '../my-registration/SpeakerInfoCard';
import useRTE from '../../../components/hook/useRTE';
import ButtonW from '../../../components/wrapper/ButtonW';
import { ManipulateStep } from '../registration';

const Step1 = ({ item }) => {
  const { t } = useTranslation();
  // const { currentUser } = useUserChange();
  const { renderFromState } = useRTE();
  const { activeStep, setActiveStep } = useContext(ManipulateStep);
  // const { handleValidRange } = useTrack();
  const { formatLocaleDateStringWithFormat,  formatLocaleTimeString,  formatCurrency } = useLocation();
  const [events, setEvents] = React.useState([]);
  const formatDate = { day: '2-digit', month: '2-digit', year: '2-digit' };

  const [activity, setActivity] = React.useState({});

  const onSubmit = () => setActiveStep(activeStep + 1); 

  const [openDialog, setOpenDialog] = React.useState(false);

  useEffect(() => {
    if (!item.id) return;
    ActivityService.findAllByEdition(item.id).then((response) => {
      if (response.status === 200) {
        setEvents(
          response.data.flatMap((activity) => {
            const { schedule } = activity;
            // console.log(place)
            return schedule.map(({ startDateTime, endDateTime, allDay, title, color }) => ({
              activity_id: activity.id,
              start: startDateTime,
              end: endDateTime,
              place: activity.place?.name,
              seq: activity.place?.number,
              allDay,
              title,
              color: color || '#2196f3',
            }));
          })
        );
        return;
      }
    });
  }, [item.id]);

  // console.log(' activities',events);

  const handleEventClick = (id) => {
    if (id) {
      ActivityService.findOne(id).then((response) => {
        if (response.status === 200) {
          setActivity(response.data);
          return;
        }
      });
      setOpenDialog(true);
      return;
    }
  };
  // console.log(activity);

  // Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa mollitia nostrum repudiandae magni, illum eaque natus delectus dolores voluptate a facere sapiente porro commodi esse cumque consectetur nam harum quibusdam.

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight="bold">
          {t('pages.registration.step1.title', { eventName: item.title })}
        </Typography>
      </Grid>
      {/* Column 1 */}
      <Grid item xs={12} sm={6} md={4} xl={3} mt={{ xs: 2, sm: 6 }} pr={2}>
        <Typography variant="h6" fontWeight="bold" mb={1}>
          {t('pages.registration.step1.column1.title')}
        </Typography>
        <Box
          component="img"
          src={item.image}
          alt="2024"
          sx={(theme) => ({
            width: { xs: '215px', sm: '115px' },
            height: { xs: '215px', sm: '115px' },
            borderRadius: '16px',
            backgroundColor: theme.palette.sidebar.backgroundColor,
            p: 1,
          })}
        />
        {item.initialDate ? (
          <>
            <Typography fontWeight="bold" mt={2}>
              {`${formatLocaleDateStringWithFormat(item.initialDate, formatDate)} > ${formatLocaleDateStringWithFormat(
                item.finalDate,
                formatDate
              )}`}
            </Typography>
            <Typography variant="body2">
              {t('pages.registration.step1.column1.text1')}
              {formatLocaleDateStringWithFormat(item.endRegistration, formatDate)}
            </Typography>
            <br />
            { item.price > 0 ?? (
              <>
                <Typography variant="h6" fontWeight="bold">
                  {formatCurrency(item.price)}
                  <br />
                </Typography>
                <Typography variant="body2">
                  {`${t('pages.registration.step1.column1.text2')} 3 x `}
                  {formatCurrency(item.price / 3)}
                </Typography>              
              </>
            )}
            <Typography textAlign={'justify'}>{item.description}</Typography>
          </>
        ) : (
          <>
            <Skeleton variant="text" width={'50%'} />
            <Skeleton variant="text" width={'50%'} sx={{ fontSize: '1rem' }} />
            <br />
            <Skeleton variant="text" width={'50%'} sx={{ fontSize: '2rem' }} />
          </>
        )}
      </Grid>
      {/* Column 2 */}
      <Grid item xs={12} sm={6} md mt={6}>

        <Typography variant="h6" fontWeight="bold" mb={2}>
          {t('pages.registration.step1.column3.title')}
        </Typography>
        <BoxW width="100%">
          <Scheduler
            {...{
              events,
              setEvents,
            }}
            readOnly
            validRange={{
              start: item.initialDate,
              end: item.finalDate,
            }}
            slotDuration="00:06:00"
            allDaySlot={false}
            navLinks
            nowIndicator={true}
            columnMinWidth={400} // 100,
            slotMinWidth={400} // 100,
            eventClick={({ event }) => handleEventClick(event.extendedProps.activity_id)}
            customHeight="60vh"
            schedulerView={'timeGridDay'}           
            headerToolbar={{
              left: 'prev,next',
              center: 'title',
              right: 'timeGridDay,dayGridMonth',
            }}
            eventContent={(arg) => {
              return (
                <TooltipW arrow placement="top" title={t('pages.registration.step1.cardHint')}>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'space-between'}
                    alignContent={'space-between'}
                    alignItems={'space-between'}
                    height={'100%'}
                    p={0.5}
                    backgroundColor={arg.backgroundColor}
                    sx={ (theme) => ({ color: theme.palette.getContrastText(arg.backgroundColor), cursor: 'pointer' })}
                  >
                    <Typography variant="body" sx={{ flex: 1 }}>{arg.event.title.length > 50 ? `${arg.event.title.substring(0, 30)}...` : arg.event.title}</Typography>
                    <Divider sx={{ border: '0.5px dashed white' }} />
                    <Typography variant="body2">{arg.event?.extendedProps?.place}</Typography>
                  </Box>
                </TooltipW>
              );
            }}
          />
        </BoxW>
        <CustomDialog
          title={activity.name}
          content={
            activity.name === undefined ? (
              <Box sx={{ height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box pl={1}>
                <SimpleContentDisplay
                  leftItem={t('pages.myRegistration.activityCard.speakers')}
                  rightItem={<SpeakerInfoCard speakers={activity.speakers} />}
                />
                {activity.type !== 'LECTURE' && (
                  <SimpleContentDisplay
                    leftItem={t('pages.myRegistration.activityCard.vacancies')}
                    rightItem={`${activity.remainingVacancies}/${activity.vacancies}`}
                  />
                )}
                {activity.workload !== '' && (
                  <SimpleContentDisplay leftItem={t('pages.myRegistration.activityCard.workload')} rightItem={activity.workload} />
                )}
                <SimpleContentDisplay
                  leftItem={t('pages.myRegistration.activityCard.type')}
                  rightItem={t(`enums.activityTypes.${activity.type}`)}
                />
                <SimpleContentDisplay
                  leftItem={t('pages.myRegistration.activityCard.presentationType')}
                  rightItem={t(`enums.editionTypes.${activity.presentationType}`)}
                />
                {activity.presentationType === 'LIVE' && (
                  <SimpleContentDisplay leftItem={t('pages.myRegistration.activityCard.place')} rightItem={activity.place.name} />
                )}
                <SimpleContentDisplay leftItem={"Horário"} rightItem={`${formatLocaleTimeString(activity.schedule[0].startDateTime)}Hs - ${formatLocaleTimeString(activity.schedule[0].endDateTime, 'HH:mm')}Hs`} />
                <SimpleContentDisplay
                  leftItem={t('pages.myRegistration.activityCard.language')}
                  rightItem={
                    <Box display="flex" alignItems="center">
                      {activity.language}
                    </Box>
                  }
                />
                {activity.description?.blocks[0]?.text !== '' && <Typography textAlign={'justify'}>{renderFromState(activity.description)}</Typography>}
              </Box>
            )
          }
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          setOpen={setOpenDialog}
        />
      </Grid>
      <Box
        width="100%"
        mt={2}
        p={{ xs: 0, sm: 2 }}
        flexDirection="row"
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="end"
      >
          <Box p={1} width={{ xs: '100%', sm: '20%' }}>
            <ButtonW fullWidth secondary onClick={onSubmit} size="large" sx={{ fontSize: 24, fontWeight: 'bold', py: 2 }}>
              {t('pages.editSatisfactionSurvey.next')}
            </ButtonW>
          </Box>
      </Box>
      {/* Column 3 
      <Grid item xs={12} sm={6} md={6} xl={6} mt={6}></Grid>
      */}
    </Grid>
  );
};

export default Step1;
