import React, { useContext, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CardActivity from '../../../components/card-activity/CardActivity';
import { AddShoppingCart, ShoppingCart } from '@mui/icons-material';
import { CartContext, ManipulateStep } from '../registration';
import ButtonsSteps from '../../../components/buttons-steps/ButtonsSteps';
import CardMainActivity from '../../../components/card-activity/CardMainActivity';

const Step2 = ({ item, lectures, nonLectures }) => {
  const { t } = useTranslation();

  // console.log(item);
  const { cart, addItem, updateCart } = useContext(CartContext);

  const { activeStep, setActiveStep } = useContext(ManipulateStep);
  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  // const [isButtonClicked, setIsButtonClicked] = React.useState(false);

  useEffect(() => {
    updateCart(cart);
  }, [cart, updateCart]);

  const isItemInCart = (id) => {
    if (!cart) return false;
    return cart.some((item) => item.id === id);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight="bold">
        {`${t('pages.registration.step2.title1')}  ${item.title}`}
        </Typography>
        <Typography sx={{ width: { xs: '100%', md: '25%' }, lineHeight: 1.2 }} mb={1}>
        {t('pages.registration.step2.subtitle1')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
      <CardMainActivity
          minWidth="100%"
          direction="row"
          activity={{
            name: item?.title,
            price: item?.price,
            description: "Entrada, palestras, certificados, crachá..."
          }}
          activities={lectures}
          idealCardHeight="600px"
          conflicts={[]}
          // buttonOptions={{
          //   title: isButtonClicked
          //     ? t('pages.registration.step2.card.buttonOff')
          //     : t('pages.registration.step2.card.buttonOn'),
          //   color: isButtonClicked ? 'success' : 'secondary',
          //   icon: isButtonClicked ? <ShoppingCart /> : <AddShoppingCart />,
          //   onClick: () => setIsButtonClicked(!isButtonClicked),
          //   disabled: isButtonClicked,
          // }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" fontWeight="bold">
          {t('pages.registration.step2.title2')}
        </Typography>
        <Typography sx={{ width: { xs: '100%', md: '25%' }, lineHeight: 1.2 }} mb={1}>
          {t('pages.registration.step2.subtitle2')}
        </Typography>
      </Grid>
      <Grid container item xs={12} spacing={3}>
        {nonLectures.length === 0 ? (
          <Box height={300} width="100%" display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h6">{t('pages.registration.step2.noActivities')}</Typography>
          </Box>
        ) : (
          nonLectures.map((activity) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={activity.id}>
              <CardActivity
                activity={activity}
                minHeight="350px"
                buttonOptions={{
                  title: isItemInCart(activity.id)
                    ? t('pages.registration.step2.card.buttonOff')
                    : t('pages.registration.step2.card.buttonOn'),
                  color: isItemInCart(activity.id) ? 'success' : 'secondary',
                  icon: isItemInCart(activity.id) ? <ShoppingCart /> : <AddShoppingCart />,
                  onClick: () => addItem(activity),
                  disabled: isItemInCart(activity.id),
                }}
              />
            </Grid>
          ))
        )}
      </Grid>
      {/* <Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
        <Box width={{ xs: '100%', md: '42%' }} py={3} mt={2} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h6" textAlign={{ xs: 'center', md: 'left' }}>
            {t('pages.registration.step2.msgRow1')}
          </Typography>
          <Typography variant="h5" fontWeight={'bold'} color={'primary.main'} sx={{ textTransform: 'uppercase', fontStyle: 'italic' }}>
            {item.title}
          </Typography>
          <Typography variant="h6">{t('pages.registration.step2.msgRow2')}</Typography>
        </Box>
      </Grid> */}
      <ButtonsSteps handleNext={handleNext} handleBack={handleBack} />
    </Grid>
  );
};

export default Step2;
