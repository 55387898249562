import { Box } from "@mui/material";
import ButtonW from "../wrapper/ButtonW";
import { useTranslation } from "react-i18next";

export default function ButtonsSteps({ handleNext, handleBack }) {
    const { t } = useTranslation();
    return (
        <Box
        width="100%"
        mt={2}
        p={{ xs: 0, sm: 2 }}
        flexDirection="row"
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="end"
      >
        <Box p={1} width={{ xs: '100%', sm: '20%' }}>
          <ButtonW fullWidth secondary onClick={handleBack} size="large" sx={{ fontSize: 24, fontWeight: 'bold', py: 2 }}>
            {t('pages.editSatisfactionSurvey.back')}
          </ButtonW>
        </Box>
        <Box p={1} width={{ xs: '100%', sm: '20%' }}>
          <ButtonW fullWidth secondary onClick={handleNext} size="large" sx={{ fontSize: 24, fontWeight: 'bold', py: 2 }}>
            {t('pages.editSatisfactionSurvey.next')}
          </ButtonW>
        </Box>
      </Box>
    )
};