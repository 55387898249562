import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useContext, useEffect } from 'react';
// import useLocation from '../../../components/hook/useLocation';
import ActivityService from '../../../services/ActivityService';
import CardActivity from '../../../components/card-activity/CardActivity';
import { RemoveShoppingCart, WarningAmberOutlined } from '@mui/icons-material';
import CustomDialog from '../../../components/custom-dialog/CustomDialog';
import { CartContext, ManipulateStep } from '../registration';
import ButtonsSteps from '../../../components/buttons-steps/ButtonsSteps';
import CardMainActivity from '../../../components/card-activity/CardMainActivity';
import { useEditionChange } from '../../../components/context/EditionChangeContext';
import { useUserChange } from '../../../components/context/UserChangeContext';
import RegistrationService from '../../../services/RegistrationService';
import { useToast } from '../../../components/context/toast/ToastContext';

const Step3 = ({ item, lectures }) => {
  const { t } = useTranslation();
  const [activities, setActivities] = React.useState([]);

  const [activitySelected, setActivitySelected] = React.useState(null);

  const { activeStep, setActiveStep } = useContext(ManipulateStep);

  const { currentEdition } = useEditionChange();

  const { currentUser } = useUserChange();

  const { addToast } = useToast();

  const { cart, removeItem, updateCart } = useContext(CartContext);
  
  const [openDialog, setOpenDialog] = React.useState(false);

  const cartTotal = cart.reduce((total, item) => total + item.price, 0);

  const handleNext = () => {
    if (currentEdition.registrationType.price === 0 && cartTotal === 0) {
      if (currentEdition && currentUser?.id) {
        const form = {
          edition: currentEdition,
          user: { id: currentUser.id },
          individualRegistrations: cart.length ? cart.map((item) => ({ activity: { id: item.id } })) : [ { activity: { id: lectures[0]?.id } }],
        };
        RegistrationService.create(form).then((response) => {
          if (response.status === 200) {
            addToast({ body: t('toastes.registrationSave'), type: 'success' });
            localStorage.removeItem('cartItems');
            setActiveStep(4);
            // RegistrationService.completeUserRegistration(response.data?.id, true).then((regResponse) => {
            //   console.log(regResponse);
            //   if(regResponse.status === 200){
            //     localStorage.removeItem('cartItems');
            //     setActiveStep(4);
            //   }
            // });
          } else if (response.status >= 400 && response.status <= 500) {
            addToast({ body: t('toastes.registrationSaveError'), type: 'error' });
          }
        });
      }
    } else {
      RegistrationService.findOneByEditionAndUser(currentEdition.id, currentUser?.id).then((response) => {
        if (response.status === 200) {
          setActiveStep(activeStep + 1);          
        } else {
          if (currentEdition && currentUser) {
            const form = {
              edition: currentEdition,
              user: currentUser,
              individualRegistrations: cart.map((activity) => ({ activity: { id: activity.id } })),
            };
            // console.log(form);
            RegistrationService.create(form)
              .then((response) => {
                // console.log(response.data);
                if (response.status === 200) {
                  setActiveStep(activeStep + 1);
                  addToast({ body: t('toastes.save'), type: 'success' });
                } else if (response.status >= 400 && response.status <= 500) {
                  // addToast({ body: t('toastes.saveError'), type: 'error' });
                  console.log(response.data);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      })
    }
  };
  const handleBack = () => setActiveStep(activeStep - 1);

  // console.log(item);

  useEffect(() => {
    if (!item.id) return;
    ActivityService.findAllByEdition(item.id).then((response) => {
        if (response.status === 200) {
          setActivities(response.data);
          return;
        }
      }).catch((error) => console.log(error));
  }, [item.id]);

  const removeItemFromCart = (item) => {
    // console.log(item)
    // console.log('removeItemFromCart')
    cart.filter((i) => i.id !== item.id);
    removeItem(item.id);
    setOpenDialog(false);
  };

  const handleOpenDialog = (activity) => {
    setActivitySelected(activity);
    setOpenDialog(true);
  };

  useEffect(() => {
    updateCart(cart);
  }, [cart, updateCart]);

  return (
    <Box>
      <Typography variant="h6" fontWeight="bold" mb={4}>
        {t('pages.registration.step3.title')}
      </Typography>
      <Box p={{ xs: 1, md: 2 }} sx={{ background: 'background.default', borderRadius: '16px', boxShadow: 'inset 0px 1px 8px 0px #878787' }}>
        <Grid container spacing={2}>
          {activities.length === 0 ? (
            <Box height={300} width="100%" display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Grid item xs={12}>
                <CardMainActivity
                  minWidth="100%"
                  direction="row"
                  activity={{
                    name: item?.title,
                    price: item?.price,
                    description: 'Entrada, palestras, certificados, crachá...',
                  }}
                  activities={lectures}
                  idealCardHeight="600px"
                  conflicts={[]}
                  // buttonOptions={{
                  //   title: 'Remover da compra',
                  //   icon: <RemoveShoppingCart />,
                  //   color: 'error',
                  //   onClick: () => handleOpenDialog(item),
                  // }}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {cart.map((activity, index) => (
                    <Grid item xs={12} md={3} key={activity.id}>
                      <CardActivity
                        key={activity.id}
                        activity={activity}
                        minHeight="350px"
                        showDetails={false}
                        buttonOptions={{
                          title: t('pages.registration.step3.card.button'),
                          icon: <RemoveShoppingCart />,
                          color: 'error',
                          onClick: () => handleOpenDialog(activity),
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <CustomDialog
        title={t('pages.registration.step3.dialog.title')}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        buttonText="Confirmar"
        buttonOtherActionText="Cancelar"
        buttonOnClick={() => removeItemFromCart(activitySelected)}
        content={
          <Box display="flex" flexDirection="column" alignItems="center">
            <WarningAmberOutlined color="warning" sx={{ mb: 2, fontSize: '10em' }} />
            <Typography textAlign={'center'}>
              {t('pages.registration.step3.dialog.text')}
              <br />
              <strong>{activitySelected?.name}</strong>?
            </Typography>
          </Box>
        }
      />
      <ButtonsSteps handleNext={handleNext} handleBack={handleBack} />
    </Box>
  );
};

export default Step3;
