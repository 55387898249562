import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { IconButton,  } from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
import useLocation from '../../../components/hook/useLocation';
import RegistrationService from '../../../services/RegistrationService';
import List from '../../../components/list/List';
import { useEditionChange } from '../../../components/context/EditionChangeContext';
import { useUserChange } from '../../../components/context/UserChangeContext';
import { useToast } from '../../../components/context/toast/ToastContext';
import { Box, colors, Grid, Typography } from '@mui/material';
import Toolbar from '../../../components/toolbar/Toolbar';
import { StyledCard } from '../../../components/context/ThemeChangeContext';
import ActivityType from '../../../enums/ActivityType';
import { isLecture } from '../../activities/list-activities/ActivityList';
import EditionType from '../../../enums/EditionType';
import CardMainActivity from '../../../components/card-activity/CardMainActivity';
import { SimpleContentDisplay } from './ActivityCard';
import FlagIcon from '../../../components/flag-icon/FlagIcon';
import SpeakerInfoCard from './SpeakerInfoCard';
import _ from 'lodash';
import ActivityService from '../../../services/ActivityService';

const MyRegistrationList = () => {
  const { t } = useTranslation();

  const { currentEdition } = useEditionChange();
  const { currentUser } = useUserChange();
  const { addToast } = useToast();

  const { formatCurrency, formatLocaleDateString, formatLocaleTimeString } = useLocation();

  const avatarColor = useMemo(() => _.sample(colors)[500], []);

  const [data, setData] = useState([]);
  const [activities, setActivities] = useState([]);

  const event = currentEdition
    ? {
        ...currentEdition,
        title: currentEdition?.shortName,
        description: currentEdition?.description,
        price: currentEdition?.registrationType?.price,
        endRegistration: currentEdition?.registrationType?.finalDateTime,
      }
    : {
        // ...currentEdition,
        title: 'Latinoware 2024',
        description: 'Entrada, palestras, certificados, crachá...',
        price: 100,
        endRegistration: new Date('2024-11-20T00:00:00.000Z'),
      };

  useEffect(() => {
    if (!currentEdition || !currentUser) return;
    RegistrationService.findOneByEditionAndUser(currentEdition.id, currentUser.id).then((response) => {
      if (response.status === 200) {
        if (response.data == null || response.data === '') {
          addToast({ body: t('toastes.fetchError'), type: 'error' });
          return;
        }
        // console.log(response.data);
        setData(response.data.individualRegistrations.map((item) => item.activity));

        return;
      }
      if (response.status === 404) {
        setData(undefined);
        return;
      }
      addToast({ body: t('toastes.fetchError'), type: 'error' });
    });
    ActivityService.findAllByEdition(currentEdition.id).then((response) => {
      if (response.status === 200) {
        if (response.data == null || response.data === '') {
          addToast({ body: t('toastes.fetchError'), type: 'error' });
          return;
        }
        // console.log(response.data);
        setActivities(response.data);
        return;
      }
      if (response.status === 404) {
        setData(undefined);
        return;
      }
      addToast({ body: t('toastes.fetchError'), type: 'error' });
    });
  }, [addToast, currentEdition, currentUser, t]);

  const nonLectures = useMemo(() => data.filter((activity) => !isLecture(activity.type)), [data]);
  const lectures = useMemo(() => activities.filter((activity) => isLecture(activity.type)), [activities]);

  // console.log(nonLectures);

  const assembleGrid = ({ schedule }) => {
    return (
      <>
        <SimpleContentDisplay leftItem={'Data'} rightItem={formatLocaleDateString(schedule.startDateTime)} />
        {new Date(schedule.startDateTime).getHours() === 0 && new Date(schedule.endDateTime).getHours() === 0 ? (
          <>
            <Typography sx={(theme) => ({ color: theme.palette.error.main })}>Atividade ocorrerá o dia todo</Typography>
            <SimpleContentDisplay leftItem={'Início'} rightItem={'07:00'} />
            <SimpleContentDisplay leftItem={'Fim'} rightItem={'21:30'} />
          </>
        ) : (
          <>
            <SimpleContentDisplay leftItem={'Início'} rightItem={formatLocaleTimeString(schedule.startDateTime)} />
            <SimpleContentDisplay leftItem={'Fim'} rightItem={formatLocaleTimeString(schedule.endDateTime)} />
          </>
        )}
      </>
    );
  };

  const columns = useMemo(
    () => [
      {
        name: 'name',
        label: t('pages.activityList.columns.name'),
        options: {
          filter: false,
        },
      },
      {
        name: 'track.name',
        label: t('pages.activityList.columns.track'),
        options: {
          filter: true,
          setCellProps: (value) => {
            return {
              style: {
                maxWidth: '200px',
              },
            };
          },
        },
      },
      {
        name: 'price',
        label: t('pages.activityList.columns.price'),
        options: {
          filter: false,
          customBodyRender: (price) => formatCurrency(price),
        },
      },
      {
        name: 'type',
        label: t('pages.activityList.columns.type'),
        enum: ActivityType,
        options: {
          searchable: false,
          filter: true,
          filterOptions: {
            renderValue: (type) => t(`enums.activityTypes.${type}`),
          },
          customFilterListOptions: {
            render: (type) => t(`enums.activityTypes.${type}`),
          },
          customBodyRenderLite: (dataIndex) => t(`enums.activityTypes.${data[dataIndex].type}`),
        },
      },
      {
        name: 'presentationType',
        label: t('pages.activityList.columns.presentationType'),
        enum: EditionType,
        options: {
          searchable: false,
          filter: true,
          filterOptions: {
            renderValue: (type) => t(`enums.editionTypes.${type}`),
          },
          customFilterListOptions: {
            render: (type) => t(`enums.editionTypes.${type}`),
          },
          customBodyRenderLite: (dataIndex) => t(`enums.editionTypes.${data[dataIndex].presentationType}`),
        },
      },
    ],
    [data, formatCurrency, t]
  );

  const options = {
    selectableRows: false,
    jumpToPage: false,
    expandableRows: true,
    renderExpandableRow: (rowData, rowMeta) => {
      // console.log(rowData, rowMeta);
      return (
        <>
          <tr>
            <td colSpan={2}>
              <Box px={2}>
                <SimpleContentDisplay
                  leftItem={t('pages.myRegistration.activityCard.speakers')}
                  rightItem={<SpeakerInfoCard speakers={data[rowMeta.rowIndex].speakers} avatarColor={avatarColor} />}
                />
              </Box>
            </td>
            <td colSpan={1}>
              <SimpleContentDisplay
                leftItem={t('pages.myRegistration.activityCard.workload')}
                rightItem={data[rowMeta.rowIndex].workload}
              />
            </td>
            <td colSpan={2}>
              <Box px={2}>
                <SimpleContentDisplay
                  leftItem={t('pages.myRegistration.activityCard.language')}
                  rightItem={
                    <Box display="flex" alignItems="center">
                      {data[rowMeta.rowIndex].language}
                      {data[rowMeta.rowIndex].languageFlag && (
                        <Box paddingLeft={1}>
                          <FlagIcon country={data[rowMeta.rowIndex].languageFlag} height="25px" />
                        </Box>
                      )}
                    </Box>
                  }
                />
              </Box>
            </td>
          </tr>
          <tr>
            <td colSpan={12}>
              <Box px={2}>
                <Typography fontSize={18} fontWeight={'bold'}>
                  {t('pages.myRegistration.activityCard.schedule')}
                </Typography>
              </Box>
              {data[rowMeta.rowIndex].schedule.length > 0 && (
                <Box>
                  <Grid container px={3} pt={1} spacing={2}>
                    {data[rowMeta.rowIndex].schedule.map((schedule, index) => (
                      <Grid item xs={12} md={2} key={index}>
                        <Fragment key={index}>{assembleGrid({ schedule: schedule })}</Fragment>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
              {data[rowMeta.rowIndex].schedule.length <= 0 && (
                <Typography sx={(theme) => ({ color: theme.palette.error.main })}>
                  {t('pages.myRegistration.activityCard.noSchedule')}
                </Typography>
              )}
            </td>
          </tr>
        </>
      );
    },
  };

  return (
    <>
      <Toolbar title={[t('layouts.sidebar.enrollments'), t('layouts.sidebar.myRegistration')]} urls="/cli/users" hasArrowBack />
      <Box p={2} flexDirection="row" display="flex" flexWrap="wrap" alignItems="center">
        <CardMainActivity
          minWidth="100%"
          direction="row"
          activity={{
            name: event?.title,
            price: event?.price,
            description: 'Entrada, palestras, certificados, crachá...',
          }}
          activities={lectures}
          idealCardHeight="600px"
          conflicts={[]}
        />
        <StyledCard p={0} elevation={4} sx={{ mt: 3 }}>
          <List
            {...{
              columns,
              options,
              data: nonLectures,
            }}
            title={t('pages.myRegistration.titleTable')}
            textLabelsCod="activityList"
            // defaultSortOrder={{ name: 'availabilityDateTime', direction: 'asc' }}
          />
        </StyledCard>
      </Box>
    </>
  );
};

export default MyRegistrationList;
